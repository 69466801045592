import { SearchLocation } from '../components/LocationPopover'

export type SearchParams = {
  region?: string
  query?: string
  types?: string[]
  date?: Date
  location?: SearchLocation
}

export const extractSearchParamsFromURL = (
  q: Record<string, any>
): SearchParams => {
  const region = q.region
  const query = (q.query as string) ?? ''
  const timestamp = +q.timestamp || undefined
  const lat = +q.lat || undefined
  const lng = +q.lng || undefined
  const radius = +q.radius || undefined
  const types = ((q.types as string) ?? '')
    .split(',')
    .filter((t) => t.length > 0)

  const date = timestamp ? new Date(timestamp) : undefined
  const location = lat && lng && radius ? { lat, lng, radius } : undefined
  return { region, query, types, date, location }
}

export const encodeSearchParams = ({
  region,
  query,
  types,
  date,
  location
}: SearchParams): Record<string, any> => {
  const encodedTypes = types?.join(',')
  const encodedDate = date?.valueOf()
  const object = {
    region,
    query,
    types: encodedTypes,
    timestamp: encodedDate,
    lat: location?.lat,
    lng: location?.lng,
    radius: location?.radius
  }
  return Object.fromEntries(
    Object.entries(object).filter(
      ([k, v]) =>
        v !== undefined &&
        !(k === 'query' && v === '') &&
        !(k === 'types' && v === '')
    )
  )
}
