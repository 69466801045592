import { ExtendedFirestoreInstance } from 'react-redux-firebase'
import useSearch from '../../hooks/useSearch'
import { Festival } from '../../types/types'
import { getAllDocuments, Indexed } from '../meilisearch'
import { generateFestivalSlug } from '../slugs'

export const useFestivalSearch = (
  filters: string,
  query: string,
  numHits?: number
) => useSearch<Festival>(filters, 'festivals', query, numHits)

export const generateFestivalId = (
  firestore: ExtendedFirestoreInstance
): string => firestore.collection('festivals').doc().id

export const createFestival = async (
  firestore: ExtendedFirestoreInstance,
  data: Omit<Festival, 'createdAt' | 'updatedAt' | 'isDeleted'>,
  festivalId: string
): Promise<void> => {
  const slug = generateFestivalSlug(data.start.toDate(), data.name)
  await firestore
    .collection('festivals')
    .doc(festivalId)
    .set({
      ...data,
      slug,
      isDeleted: false,
      createdAt: firestore.FieldValue.serverTimestamp(),
      updatedAt: firestore.FieldValue.serverTimestamp()
    })
}

export const getFestivalDoc = async (
  firestore: ExtendedFirestoreInstance,
  festivalId: string
): Promise<firebase.firestore.DocumentSnapshot<Festival>> => {
  const festivalDoc = await firestore
    .collection('festivals')
    .doc(festivalId)
    .get()
  return festivalDoc as firebase.firestore.DocumentSnapshot<Festival>
}

export const updateFestival = async (
  firestore: ExtendedFirestoreInstance,
  data: Partial<Festival>,
  festivalId: string
): Promise<void> => {
  await firestore
    .collection('festivals')
    .doc(festivalId)
    .update({
      ...data,
      isDeleted: false,
      updatedAt: firestore.FieldValue.serverTimestamp()
    })
}

export const deleteFestival = async (
  firestore: ExtendedFirestoreInstance,
  festivalId: string
): Promise<void> => {
  await firestore.collection('festivals').doc(festivalId).update({
    isDeleted: true
  })
}

export const getAllPublicFestivals = async () => {
  const festivals = await getAllDocuments<
    Indexed<Festival> & { countryCode: string }
  >('festivals')

  return festivals.filter((festival) => festival.isDeleted === false)
}
