// Convert the given number from degrees to radians.
const rad = (angle: number) => angle * (Math.PI / 180)

// Convert the given number from radians to degree.
const deg = (angle: number) => angle * (180 / Math.PI)

/*
 * Define a square region around a given point using the given distance (km)
 * Returns a JSON object with North, East, South and West region boundaries.
 */
export const getRegion = (lat: number, lon: number, dist: number) => {
  const r = 6371.009 // Earth radius in kms
  const theta = rad(90 - lat) // Absolute bearing (polar angle) in radians
  const phi = rad(180 + lon) // Azimuthal angle in radians
  const deltaTheta = dist / r
  const deltaPhi = dist / (r * Math.sin(theta))

  const S = 90 - deg(Math.abs(theta - deltaTheta / 2))
  const N = 90 - deg(Math.abs(theta + deltaTheta / 2))
  const W = (deg(phi - deltaPhi / 2) % 360) - 180
  const E = (deg(phi + deltaPhi / 2) % 360) - 180

  return { N, E, S, W }
}
